import React, { useEffect, useState } from "react";
import Stserviceitems from "./StserviceItem";
import Reveal from "react-reveal/Reveal";
import lang from "../../../lang/lang";
import { getAbout } from "../../../services/api";

const Sservice = () => {
  const [courses, setCourses] = useState([])
  useEffect(() => {
    const random = () => getAbout().then(function(result) {
      console.log(result);
      setCourses(result.data)
    })

    random()
  })
  if (typeof courses === 'undefined') {
    return(
      <h1>Loading...</h1>
    )
  }
  return (
    <section className="agency_service_area bg_color" style={{ marginTop: "50px" }}>
      <div className="container">
        <ul>
          {courses && courses.map((course, key) => (
            <div key={key}>{lang === 'ENGLISH' ? course.about : course.arabout}</div>
          ))}
        </ul>
        <div className="row mb_30 ">
          <div className="row mt-5 mb-4">
         
            <div className="col-lg-6 col-sm-6">
              <div className="container">
                <video
                  width="100%"
                  playsInline
                  loop
                  muted
                  controls
                  src="https://amanialotaibi.com/assets/amani_videos/%D9%81%D9%8A%D8%AF%D9%8A%D9%88%20%D8%AA%D8%B9%D8%B1%D9%8A%D9%81%D9%8A%20%D8%B9%D9%86%20%D8%A3%D9%85%D8%A7%D9%86%D9%8A.mp4"
                ></video>
              </div>
            </div>
          </div>
          <div className="abouttext mt-5 mb-4">
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sservice;
