import axios from "axios";

const URL = 'https://api.resto.oryxintl.co:3000'

// const URL = 'http://localhost:3000'


export const signUpUser = async(data) => {
    try {
        return await axios.post(`${URL}/api/user/register-user`, data)
    } catch (error) {
        console.log('Error while calling signup api', error.response.data);
    }
}

export const signInUser = async(data) => {
    try {
        const config = {
            headers: {
                "Content-type": "application/json",
            }
        }
        const result = await axios.post(`${URL}/api/user/signin`, data, config)
        return result.data;
    } catch (error) {
        console.log('Error while calling signin api', error.response.data);
    }
}

export const getCourses = async() => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/courses`, config)
    } catch (error) {
        console.log('Error', error);
    }
}  

export const getSingleCourses = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/courses/${data}`, config)
    } catch (error) {
        console.log('Error', error);
    }
}  



export const getEBooks = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/ebooks`, config)
    } catch (error) {
        console.log('Error', error);
    }
}

export const getSingleEBooks = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/ebooks/${data}`, config)
    } catch (error) {
        console.log('Error', error);
    }
}

export const getMeditation = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/meditation`, config)
    } catch (error) {
        console.log('Error', error);
    }
}

export const getSingleMeditation = async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/meditation/${data}`, config)
    } catch (error) {
        console.log('Error', error);
    }
}


export const getSession= async() => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/session`, config)
    } catch (error) {
        console.log('Error', error);
    }
}

export const getSingleSession= async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/session/${data}`, config)
    } catch (error) {
        console.log('Error', error);
    }
}



export const getArticles= async() => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/article`, config)
    } catch (error) {
        console.log('Error', error);
    }
}

export const getSingleArticles= async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/article/${data}`, config)
    } catch (error) {
        console.log('Error', error);
    }
}

export const getPodcasts= async() => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/podcast`, config)
    } catch (error) {
        console.log('Error', error);
    }
}


export const getSinglePodcasts= async(data) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.get(`${URL}/api/client/podcast/${data}`, config)
    } catch (error) {
        console.log('Error', error);
    }
}


export const getAbout= async() => {
    try {

        return await axios.get(`${URL}/api/client/about`)
    } catch (error) {
        console.log('Error', error);
    }
}

export const getTestimonials = async() => {
    try {
        return await axios.get(`${URL}/api/client/testimonial`)
    } catch (error) {
        console.log('Error', error);
    }
}


// Subscription

export const postSubscription = async(data, params) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }
        return await axios.post(`${URL}/api/client/subscribe/${params}`, data, config)
    } catch (error) {
        console.log('Error', error);
    }
}


export const getS = async(params) => {
    const config = {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }

    return await axios.get(`${URL}/api/client/single/${params}`, config)
}


export const postSubscribe = async(params, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }

    return await axios.post(`${URL}/api/client/single/${params}`, data, config)
}


export const getSubsCont = async(params) => {
    const config = {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }

    return await axios.get(`${URL}/api/client/content/${params}`, config)
}

export const disableDates = async() => {
    const config = {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }

    return await axios.get(`${URL}/api/user/disable-dates`, config)
}


export const getDateOfSession = async() => {
    const config = {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    }
    return await axios.get(`${URL}/api/client/buy-session/${localStorage.getItem('id')}`, config)
}


export const getAudio =  async(data) => {
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/content/get-audio`, data, config)
    } catch (error) {
        console.log(error);
    }
}

export const getVideo = async(data) => {
    console.log(data);
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/video`, data, config)
    } catch (error) {
        console.log(error);
    }
}

export const getLink = async(data) => {
    console.log(data);
    try {
        const config = {
            headers: {
                Authorization : `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        }

        return await axios.post(`${URL}/api/user/link`, data, config)
    } catch (error) {
        console.log(error);
    }
}




