import React, { useEffect, useState } from "react";
import Sectitle from "../Title/Sectitle";
import BuyNowCouresIteam from "../Team/BuyNowCouresIteam";
import { useNavigate, useParams } from "react-router-dom";
import { disableDates, getSingleCourses, getSingleEBooks, getSingleSession, postSubscription } from "../../services/api";
import lang from "../../lang/lang";
import Axios from "axios";
import { DatePicker } from '@mui/lab';
import CustomNavbar from "../CustomNavbar";
import FooterAmani from "../Footer/FooterAmani";

const BuySession = () => {
  const [courses, setCourses] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);

  const id = localStorage.getItem('id');
  const Lange = localStorage.getItem('lang')

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(parseInt(event.target.value));
  };

  const { paramsfour } =  useParams();

  useEffect(() => {
    disableDates()
      .then(response => {
        // Assuming the response contains the array of disabled dates, update the selectedDates state with the fetched disabled dates
        setSelectedDates(response.data.disabledDates);
      })
      .catch(error => {
        console.error('Error fetching disabled dates:', error);
      });
  }, []);

  useEffect(() => {
    const fetchSessionData = () => {
      getSingleSession(paramsfour)
        .then((result) => {
          console.log(result);
          setCourses(result.data);
        })
        .catch((error) => {
          console.error('Error fetching session data:', error);
        });
    };
    fetchSessionData();
  }, []);

  const handleDateChange = (event, index) => {
    const newSelectedDates = [...selectedDates];
    newSelectedDates[index] = event.target.value;
    setSelectedDates(newSelectedDates);
  };
  const renderDateInputs = () => {
    const dateInputs = [];
    for (let i = 0; i < parseFloat(courses.total_number); i++) {
      dateInputs.push(
        <div key={i}>
          <label htmlFor={`date${i}`}>Select Date:</label>
          <input
            type="date"
            id={`date${i}`}
            value={selectedDates[i] || ''}
            onChange={(event) => handleDateChange(event, i)}
          />
          <label htmlFor={`time${i}`}>Select Time:</label>
          <input
            type="text"
            placeholder="12:00 AM"
            id={`time${i}`}
            value={selectedDates[i]?.split('T')[1] || ''}
            onChange={(event) => handleDateChange(event, i)}
          />
        </div>
      );
    }
    return dateInputs;
  };

  const navigate = useNavigate();
  
  const handlePayment = async () => {

    const paymentData = {
      InvoiceAmount: courses.price,
      CurrencyIso: "kwd",
    };
    try {
      const initiateResponse = await Axios.post(
        "https://api.resto.oryxintl.co:3000/initiatepayment",
        paymentData
      );
    
      // Assuming the response contains the necessary data to proceed with payment execution
      // (e.g., transaction ID or other payment-related data), extract it from the response
      const transactionId = initiateResponse.data.transactionId;

      // After successful payment initiation, you can proceed with payment execution
      const executePaymentData = {
        PaymentMethodId: 2,
        invoiceValue: courses.price,
        CallBackUrl: `https://amani.oryxintl.co/#/success/${courses.price}/${id}/${paramsfour}`,
        ErrorUrl: 'https://amani.oryxintl.co/#/fail',
        // Add any other necessary data for payment execution...
      };

      // Execute the payment using the /executePayment API
      const executeResponse = await Axios.post(
        "https://api.resto.oryxintl.co:3000/executePayment",
        executePaymentData
      );

      // Assuming the payment was successful and you receive some data in the executeResponse,
      // call the postSubscription function with the necessary data.
      const subscriptionData = {
        amount: courses.price,
        id: id,
        dates: selectedDates,
        paymentStatus: 'success', // You can include the payment status in the subscription data
        // Add any other relevant data for the subscription...
      };
      localStorage.setItem('dates', selectedDates)
      // Call the postSubscription function with the subscription data and the paramsfour as needed.
      // const subscriptionResponse = await postSubscription(subscriptionData, paramsfour);
      console.log(executePaymentData);
      window.open(executeResponse.data.Data.PaymentURL)

      if (courses.price === '0') {
        postSubscription(executePaymentData, paramsfour)
      }

    } catch (error) {
      console.log(error);
    }
  }

  if (typeof courses === 'undefined') {
    return (
      <h1>Loading...</h1>
    );
  }

  return (
    <>
    <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      <section className="experts_team_area sec_pad" style={{ marginTop: "50px" }}>
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title="Session"
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <BuyNowCouresIteam
              teamImage={`https://api.resto.oryxintl.co:3000/${courses.image}`}
              memberN={Lange === 'ENGLISH' ? courses.name : courses.arname}
              memberd={courses.price}
            />
          </div>

          <div className="col-lg-6 col-sm-6">
            <div className="Heading mt-2">
              <h5>{Lange === 'ENGLISH' ? courses.name : courses.arname}</h5>
            </div>
            <div className="PricesCoures mt-4 mb-3">
              <h5> {courses.price} KWD</h5>
            </div>
            <select type="text" class="form-control" name="payment" onChange={handlePaymentMethodChange}>
              <option value="1">KNET</option>
              <option value="2">VISA/MASTER</option>
            </select>
            <h2>Add Dates for Session:-</h2>
            {renderDateInputs()}
            <div className="BuyNow">
              <button className="signup_btn btn_hover saas_banner_btn mt_40" onClick={handlePayment}>
                Buy Now
              </button>
            </div>
            <div className="Description mt-4">
              <h5>{Lange === 'ENGLISH' ? courses.des : courses.ardes}</h5>
              <p className="mt-2 p-1">{Lange === 'ENGLISH' ? courses.name : courses.arname}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
      <FooterAmani />
    </>
  );
};

export default BuySession;
