import React from 'react';
import { NavLink } from "react-router-dom";
import lang from '../../lang/lang';

const Coures =()=> {
    return (
        <section className="security_integration_area chat_integration_area" style={{marginTop:"50px"}}>
          <div className="container">
            <div className="border-bottom"></div>
            {/* <div className="chat_title text-center">
              <h2 className="wow fadeInUp">
                <span>TOP Features</span>
              </h2>
              <p>ERP - The one-stop-shop solution for every business need.</p>
            </div> */}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <NavLink to="/CouresBook" className="s_integration_item">
                  <img src={require("../../img/new-home/Courses.png")} style={{width:"50%"}} alt="" />
                  <h5>{lang.S_COURSE}</h5>
                </NavLink>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <NavLink to="/MeditationdataBook" className="s_integration_item">
                 <img src={require("../../img/new-home/Meditation.png")} style={{width:"50%"}} alt="" />
                  <h5>{lang.S_MEDITATION}</h5>
                </NavLink>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <NavLink to="/SessiondataBook" className="s_integration_item">
                 <img src={require("../../img/new-home/Session.png")} style={{width:"50%"}} alt="" />
                  <h5>{lang.S_SESSION}</h5>
                </NavLink>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <NavLink
                  to="/EbookdataBook"
                  className="s_integration_item"
                >
                 <img src={require("../../img/new-home/Ebook.png")} style={{width:"50%"}} alt="" />
                  <h5> {lang.S_EBOOK}</h5>
                </NavLink>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <NavLink to="/ArticlesdataBook" className="s_integration_item">
                 <img src={require("../../img/new-home/Articles.png")} style={{width:"50%"}} alt="" />
                  <h5> {lang.S_ARTICLES}</h5>
                </NavLink>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <NavLink
                  to="/PodastdataBook"
                  className="s_integration_item"
                >
                 <img src={require("../../img/new-home/Podcast.png")} style={{width:"50%"}} alt="" />
                  <h5>{lang.S_POSCAST}</h5>
                </NavLink>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-4">
                <NavLink to="/ProjectAndManagement" className="s_integration_item">
                 <img src={require("../../img/new-home/campfire.png")} style={{width:"50%"}} alt="" />
                  <h5>PROJECT MANAGEMENT</h5>
                </NavLink>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4">
                <NavLink to="/Pos" className="s_integration_item">
                 <img src={require("../../img/new-home/campfire.png")} style={{width:"50%"}} alt="" />
                  <h5>POS</h5>
                </NavLink>
              </div> */}
            </div>
          </div>
        </section>
      );
}

export default Coures;