import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import SessiondataBookDetail from "../components/Team/SessiondataBookDetail";
import FooterAmani from "../components/Footer/FooterAmani";
const SessiondataBook = () => {
  return (
    <div className="body_wrapper">
        <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      {/* <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="Img-05.jpg"
        Ptitle="Our SessiondataBook"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      /> */}
      <SessiondataBookDetail/>
      {/* <FooterTwo FooterData={FooterData} /> */}
      <FooterAmani/>
    </div>
  );
};
export default SessiondataBook;
