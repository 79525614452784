import React, { useState } from 'react';
import {NavLink, useNavigate} from 'react-router-dom'
import { signUpUser } from '../services/api';


const initialValues = {
    mail: '',
    password: '',
    isAdmin: false
}

const SignUpForm =()=>{
    const [sign, setSign] = useState(initialValues)

    
    const onValueChange = (e) => {
        setSign({...sign, [e.target.name]: e.target.value});
    }

    const navigate = useNavigate();

    const signupUser = async () => {
        try {
        let res = await signUpUser(sign);
        console.log(res);
        if (res) {
            navigate('/SignIn')
        }
        } catch (error) {
        console.log(error);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          email: data.get('email'),
          password: data.get('password'),
        });
      };
    return(
        <section className="sign_in_area bg_color sec_pad"  style={{ marginTop: "50px" }}>
        <div className="container">
            <div className="sign_info">
                <div className="row">
                    
                    <div className="col-lg-8">
                        <div className="login_info">
                            <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Sign Up and Start Learning!</h2>
                            <form onSubmit={handleSubmit} className="login-form sign-in-form">
                                <div className="form-group text_box">
                                    <label className="f_p text_c f_400">Email</label>
                                    <input type="text" onChange={(e) => onValueChange(e)} placeholder="Userame" name='mail'/>
                                </div>
                                <div className="form-group text_box">
                                    <label className="f_p text_c f_400">Password</label>
                                    <input type="password" onChange={(e) => onValueChange(e)} name='password' placeholder="******"/>
                                </div>
                                {/* <div className="extra mb_20">
                                    <div className="checkbox remember">
                                        <label>
                                            <input type="checkbox"/> Remember me
                                        </label>
                                    </div>
                                   
                                    <div className="forgotten-password">
                                        <a href="/#">Forgot Password?</a>
                                    </div>
                                </div> */}
                                <div className="d-flex justify-content-between align-items-center">
                                    <button
                                     type="submit" 
                                     className="btn_three"
                                     onClick={() => signupUser()}
                                    >Sign Up</button>
                                    <div className="social_text d-flex ">
                                        <div className="lead-text">Already have an account? <NavLink to="/SignIn">Log In</NavLink></div>
                                       
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default SignUpForm;