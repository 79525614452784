const cond = localStorage.getItem("lang");

console.log({ cond });

const ENGLISH = {
  HOME: "HOME",
  ABOUT: "ABOUT",
  SERVICES: "SERVICES",
  CONTACT: "CONTACT",
  MY_SERVICES: "MY SERVICES",
  //   cOURES
  S_COURSE: "Coures",
  S_MEDITATION: "Meditation",
  S_SESSION: "Session",
  S_EBOOK: "Ebook",
  S_ARTICLES: "Articles",
  S_POSCAST: "Podcast",

  H_HOME: "WHAT OUR CLIENTS SAY ABOUT US",
  H_HOME1: "Subscribe to our newsletter",
  H_SUBSCRIBE: "Subscribe ",
  H_INPUTF: "Enter Your Email Address",
  F_HEADING: "Connect With Us",

  //   Contact

  C_PHONENUMBER:"+96556645726",

  C_ContactInform: "Contact Info",

  C_FINDUS: "Find Us",
  C_PHONE: "Phone",
  C_FOLLOWUS: "Follow Us",
  C_WRITEUS: "Contact Details",

  C_ADDRESS:
    "Office no:07, 04tth floor, Al Fajer complex, Tunis st, Hawally, Kuwait",

  C_HAVEQUESTION: "Have A Question?",



  Name:"Enter Your Name",
  Email:"Enter Your Email",
  Phone:"Enter Your Phone",
  Service:"Select Your Service",
  Message:"Enter Your MessageEnter Your ",

  Send:"Send",

  Yourmessagesucces:"Your message succesfully sent!",
  Oppssomethingwrong:"Opps! There is something wrong. Please try again",

  // About

  C_ABOUTUS: "Our Message",
  C_OURMESSAGE: "Project Goals",
  C_OURMESSAGEPARAGRAPH:
    "Work with a professional and fruitful methodology to achieve fun in the journey of self-knowledge and improve the quality of life and relationships through electronic and live training programs and counseling sessions.",
  C_PROJECTGOAL: "",
  C_PROJECTGOALFIRST: "Organizing electronic and live programs",
  C_PROJECTGOALSECOND: "Production of audio and video materials",
  C_PROJECTGOALTHIRD: "Offer online and live sessions",
  C_OURVISION: "Our Vision",
  C_OURVISIONPARAGRAPH:
    "Finding simplified and effective solutions and means to improve the quality of life and relationships by adopting the self-development methodology first.",
  C_MISSION: "Our Mission",
  C_MISSIONPARAGRAPH:
    "Delivering information in the simplest way by putting it into action and working with it.",

  // Medition

  P_Price: "KWD",

  //   Coures
  EmotionalAwareness: "Emotional Awareness",
  ManCodeLevel1: "Man Code Level 1",
  AttendanceChallenge: "Attendance Challenge",
  ChakrasDetox: "Chakras Detox",
  ThankfulForRelationships: "Thankful For Relationships",
  WOMENCODE: "WOMEN CODE",
  Yourdreamrelationshipisreal: "Your dream relationship is real!",

  // Meditation

  EnergyPurification: "Energy Purification",
  ReleaseBlockedEmotion: "Release Blocked Emotion",
  EnergyCultivation: "Energy Cultivation",
  HealingTheInnerChild: "Healing The Inner Child",
  HealingRelationships: "Healing Relationships",
  ChakraBalancing: "Chakra Balancing",
  HealingRelationshipsWithMother: "Healing Relationships With Mother",

  // Session

  Healing: "Healing",
  Menifistation: "Menifistation",
  Planning: "Planning",
  SelfLovePackage: "Self Love Package",
  Coaching: "Coaching",

  // EBooks

  EbbokRelationship: "4 steps to restore a relationship after a breakup",

  // Articles

  Selflove: "Self love",
  EnergyExtortion: "Energy Extortion",
  levelsResponsibility: "levels Responsibility",
  ChakraRelationshipSeries: "Chakra Relationship Series",

  // Podcast

  chakras: "chakras",
  chakrasInDimensions: "chakras in dimensions",
  TheSevenMajorChakras: "The seven major chakras",
  RootChakra: "Root chakra",
  TheRootChakraPartTwo: "The Root Chakra, Part Two",
  Thankyounavel: "Thank you navel",
  Navelchakrainhealth: "Navel chakra - in health",
  Thenavelchakrainrelationships: "The navel chakra -in relationships",
  Solarplexuschakra: "Solar plexus chakra",
  SolarPlexusChakraPartTwo: "Solar Plexus Chakra/Part Two",
  SolarPlexusChakraInRelationships: "Solar Plexus Chakra - In Relationships",

  Femininityandmasculinityinthechakras:
    "Femininity and masculinity in the chakras",
  Thankfulheart: "Thankful heart",
  Throatchakrapartone: "Throat chakra / part one",
  TheHeartChakraPartTwo: "The Heart Chakra, Part Two",
  TheHeartChakraPartThree: "The Heart Chakra/Part Three",
  TheThroatChakraPartTwo: "The Throat Chakra, Part Two",
  Throatchakrapartthree: "Throat chakra / part three",
  ThirdEyeChakraAjna: "Third Eye Chakra/Ajna",
  TheThirdEyeChakraAjnaPartTwo:
    "The Third Eye Chakra/Ajna Part Two/How Illusions Can Hurt Us",
  TheThirdEyeChakraAjnaPartThree:
    "The Third Eye Chakra/Ajna Part Three/How perceptions affect our reality",
  CrownChakraTherelationshipofthecrown:
    "Crown Chakra / The relationship of the crown chakra with awareness",
  CrownChakraPartTwo: "Crown Chakra / Part Two / Convictions Exercise",
  TheCrownChakraPartThree: "The Crown Chakra, Part Thr",
  Importantnotesonthechakraspartone: "Important Points on the Chakras/Part One",
  ImportantPointsontheChakrasPartTwo:
    "Important Points on the Chakras/Part Two",
  ImportantPointsontheChakrasPartThree:
    "Important Points on the Chakras/Part Three",
};

const ARABIC = {
  // Navbar
  HOME: "الرئيسية الصفحة",
  ABOUT: "معلومات عنا",
  SERVICES: "خدمات",
  CONTACT: "اتصل بنا",
  MY_SERVICES: 'خدماتي',

  //   cOURES
  S_COURSE: "برامج",
  S_MEDITATION: "تأملات",
  S_SESSION: "جلسات",
  S_EBOOK: "كتب",
  S_ARTICLES: "مقالات",
  S_POSCAST: "بودكاست",

  //   Home Header

  H_HOME: "ما يقوله عملائنا عنا",

  H_HOME1: "انضم إلى مجلتنا",
  H_SUBSCRIBE: "انضم ",
  H_INPUTF: "سجل ايميلك  ",

  //   Contact

  C_PHONENUMBER:"٠٠٩٦٥٥٦٦٤٥٧٢٦",
  C_ContactInform: "بيانات المتصل",
  C_FINDUS: "تجدنا",
  C_PHONE: "هاتف",
  C_FOLLOWUS: "تابعنا",
  C_WRITEUS: "تواصل معنا",
  C_HAVEQUESTION: "تواصل معنا",

  C_ADDRESS: "رقم المكتب: ٧ الدور : ٤ مجمع الفجر,شارع تونس حولي، الكويت",

  Name:"أدخل أسمك",
  Email:"أدخل بريدك الإلكتروني",
  Phone:"أدخل رقم هاتفك",
  Service:"حدد الخدمات ",
  Message:"أدخل رسالتك ",

  Send:"إرسال",

  Yourmessagesucces:"تم إرسال رسالتك بنجاح!",
  Oppssomethingwrong:"عفوا! هناك شيء خاطئ. حاول مرة اخرى",
  //   ABOUT PAGE

  C_ABOUTUS: "معلومات عنا",
  C_OURMESSAGE: "رسالتنا",
  C_OURMESSAGEPARAGRAPH:
    "العمل بمنهجية احترافية ومثمرة لتحقيق المتعة في رحلة التعرف على الذات وتحسين . جودة الحياة والعلاقات من خلال برامج وجلسات ارشادية تدريبية إلكترونية وحية",
  C_PROJECTGOAL: "أهداف المشروع",
  C_PROJECTGOALFIRST: "تنظيم برامج إلكترونية وحية",
  C_PROJECTGOALSECOND: "إنتاج مواد سمعية ومرئية",
  C_PROJECTGOALTHIRD: "تقديم جلسات إلكترونية وحية",
  C_OURVISION: "رؤيتنا",
  C_OURVISIONPARAGRAPH:
    "ايجاد حلول ووسائل مبسطة وفعالة تعين على تحسين جودة الحياة والعلاقات من خلال تبني منهجية تنمية الذات أولا.",
  C_MISSION: "مهمتنا",
  C_MISSIONPARAGRAPH:
    "توصيل المعلومة بأبسط الطرق من خلال وضعها في الفعل والعمل بها .",

  P_Price: "دينار كويتي",

  //   Coures
  EmotionalAwareness: "الوعي بالمشاعر",
  ManCodeLevel1: "كود الرجل المستوى 1",
  AttendanceChallenge: "تحدي الحضور",
  ChakrasDetox: "ديتوكس شاكرات",
  ThankfulForRelationships: "شاكرا العلاقات",
  WOMENCODE: "كود المرأه",
  Yourdreamrelationshipisreal: "علاقة أحلامك ممكنة!",

  // Meditation

  EnergyPurification: "تأمل تنقية الطاقة",
  ReleaseBlockedEmotion: "تأمل تحرير المشاعر المعيقة",
  EnergyCultivation: "تأمل زراعة الطاقة",
  HealingTheInnerChild: "تأمل شفاء الطفل الداخلي",
  HealingRelationships: "تأمل شفاء العلاقات",
  ChakraBalancing: "تأمل موازنة الشاكرات",
  HealingRelationshipsWithMother: "تأمل شفاء العلاقة مع الأم",

  // Session

  Healing: "جلسة تنظيف بالكريستال",
  Menifistation: "جلسة تجلي الأهداف",
  Planning: "جلسة التخطيط",
  SelfLovePackage: "جلسة حب الذات",
  Coaching: "جلسة كوتشينج استشارة",

  // EBooks

  EbbokRelationship: "٤خطوات لاستعادة علاقتك بعد الانفصال",

  // Articles

  Selflove: "حب الـذات",
  EnergyExtortion: "الإبتزاز الطاقي",
  levelsResponsibility: "مستويات المسؤولية  ",
  ChakraRelationshipSeries: "سلسلة شاكرا العلاقات",

  // Podcast

  chakras: "الشاكرات",
  chakrasInDimensions: "الشاكرات في الابعاد",
  TheSevenMajorChakras: "الشاكرات السبعة الرئيسية",
  RootChakra: "شاكرا الجذر",
  TheRootChakraPartTwo: "شاكرا الجذر /الجزء الثاني",
  Thankyounavel: "شاكرا السرة",
  Navelchakrainhealth: "شاكرا السرة -في الصحه",
  Thenavelchakrainrelationships: "شاكرا السرة -في العلاقات",
  Solarplexuschakra: "شاكرا الضفيرة الشمسية",
  SolarPlexusChakraPartTwo: "شاكرا الضفيرة الشمسية/الجزء الثاني",
  SolarPlexusChakraInRelationships: "شاكرا الضفيرة الشمسية-في العلاقات",

  Femininityandmasculinityinthechakras: "الأنوثة والذكورة في الشاكرات",
  Thankfulheart: "شاكرا القلب",
  Throatchakrapartone: "شاكرا القلب/الجزء الثاني",
  TheHeartChakraPartTwo: "شاكرا القلب/الجزء الثالث",
  TheHeartChakraPartThree: "شاكرا الحلق /الجزء الاول",
  TheThroatChakraPartTwo: "شاكرا الحلق /الجزء الثاني",
  Throatchakrapartthree: "شاكرا الحلق /الجزء الثالث",
  ThirdEyeChakraAjna: "شاكرا العين الثالثة/الآجنا /",
  TheThirdEyeChakraAjnaPartTwo:
    "شاكرا العين الثالثة/الآجنا الجزء الثاني /كيف يمكن للأوهام ان تؤذينا",
  TheThirdEyeChakraAjnaPartThree:
    "شاكرا العين الثالثة/الآجنا الجزء الثالث /كيف للتصورات ان تؤثر في واقعنا",
  CrownChakraTherelationshipofthecrown: "شاكرا التاج/علاقة شاكرا التاج بالوعي",
  CrownChakraPartTwo: "شاكرا التاج /الجزء الثاني/تمرين القناعات",
  TheCrownChakraPartThree: "شاكرا التاج /الجزء الثالث",
  Importantnotesonthechakraspartone: "ملاحظات مهمة في الشاكرات /الجزء الاول",
  ImportantPointsontheChakrasPartTwo: "نقاط مهمة في الشاكرات/الجزء الثاني",
  ImportantPointsontheChakrasPartThree: "نقاط مهمة في الشاكرات/الجزء الثالث",

  //   Footer Header
  F_HEADING: "اتصل بنا",
};

const data = { ENGLISH, ARABIC };
export default data[cond];
