import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import lang from "../lang/lang";
import '../../src/App.css';


const CustomNavbar = ({ mClass, nClass, cClass, slogo, hbtnClass }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
  };
  return (
    <div top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className="header_area">
        <div className="Topheader mt-4">
          <div style={{display:"flex", flexWrap:"wrap", columnGap:"10px"}}>
            {token ? (
              <NavLink onClick={handleLogout} to='/SignIn'>
                Logout
              </NavLink>
            ) : (
              <NavLink to="/SignIn">
                <img src={require("../img/home4/Login.png")} alt="" />
              </NavLink>
            )}
            <NavLink
              to="#"
              onClick={() => {
                localStorage.setItem(
                  "lang",
                  localStorage.getItem("lang") === "ENGLISH" ? "ARABIC" : "ENGLISH"
                );
                window.location.reload();
              }}
            >
              {localStorage.getItem("lang") === "ENGLISH" ? (
                <div onClick={() => localStorage.setItem("lang", "ENGLISH")}>
                  <img src={require("../img/home4/Arabic.png")} alt="" />
                </div>
              ) : (
                <div onClick={() => localStorage.setItem("lang", "ARABIC")}>
                  <img src={require("../img/home4/English.png")} alt="" />
                </div>
              )}
            </NavLink>
          </div>
        </div>
        <div className="container" style={{ textAlign: "center" }}>
          <Link className={`navbar-brand ${slogo}`} to="/">
            <img src={require("../img/AmaniHeaderLogo.png")} />
            <img src={require("../img/AmaniHeaderLogo.png")} alt="" />
          </Link>
        </div>
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <div className={`container ${cClass}`}>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                <li className="nav-item">
                  <NavLink title="Home" className="nav-link" to="/">
                    {lang.HOME}
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink title="About" className="nav-link" to="/About">
                    {lang.ABOUT}
                  </NavLink>
                </li>

                <li className="dropdown submenu nav-item">
                  <Link
                    title="SERVICES"
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    to="#"
                  >
                    {lang.SERVICES}
                  </Link>
                  <ul role="menu" className=" dropdown-menu">
                    <li className="nav-item">
                      <NavLink title="Coures" className="nav-link" to="/CouresBook">
                        {lang.S_COURSE}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink title="Medition" className="nav-link" to="/MeditationdataBook">
                        {lang.S_MEDITATION}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink title="Sessiond" className="nav-link" to="/SessiondataBook">
                        {lang.S_SESSION}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink title="Ebook" className="nav-link" to="/EbookdataBook">
                        {lang.S_EBOOK}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink title="Articles" className="nav-link" to="/ArticlesdataBook">
                        {lang.S_ARTICLES}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink title="Podast" className="nav-link" to="/PodastdataBook">
                        {lang.S_POSCAST}
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <NavLink title="Contact" className="nav-link" to="/Contact">
                    {lang.CONTACT}
                  </NavLink>
                </li>

                {token ? (
                  <li className="nav-item">
                    <NavLink className="nav-link" to={`/services/${localStorage.getItem('id')}`}>
                      {lang.MY_SERVICES}
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default CustomNavbar;
