import React from "react";

const ErpTestimonial = () => {
  return (
    <>
      <div className="container">
        <div className="dot">
          <img
            src={require("../../img/new-home/LeftDot.png")}
            style={{ width: "50%" }}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default ErpTestimonial;
