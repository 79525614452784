import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import lang from '../../lang/lang';
import { getTestimonials } from '../../services/api';

const MarketingTestimonial = (props) => {

    const [courses, setCourses] = useState([])
    const Lang = localStorage.getItem('lang')

  useEffect(() => {
    const random = () => getTestimonials().then(function(result) {
      console.log(result);
      setCourses(result.data)
    })

    random()
  }, [])

  if (typeof courses === 'undefined') {
    return(
      <h1>Loading...</h1>
    )
  }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const { BannerData, bgColor } = props;

    return (
        <section className={`agency_testimonial_area sec_pad ${bgColor}`}>
            <div className="container">
                <h2 className="f_size_30 f_600 t_color3 l_height40 text-center mb_60" style={{color: '#b31336'}}>{lang.H_HOME}</h2>
                <div className="agency_testimonial_info">
                    <Slider className="testimonial_slider" {...settings}>
                        {courses && courses.map(item => (
                            <div className="testimonial_item text-center left" key={item.id}>
                                {/* <div className="author_img"><img src={require('../../img/home4/' + item.image)} alt=""/></div>
                                <div className="author_description">
                                    <h4 className="f_500 t_color3 f_size_18">{item.authorName}</h4>
                                    <h6>{item.authorPost}</h6>
                                </div> */}
                                <h4 className="f_500 t_color3 f_size_18">{Lang === 'ENGLISH' ? item.namew : item.arname}</h4>
                                <h6>{Lang === 'ENGLISH' ? item.comment : item.arcomment}</h6>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default MarketingTestimonial;
