import React, { useEffect, useState } from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
import lang from "../../lang/lang";
import { getCourses } from "../../services/api";
import { useNavigate } from "react-router-dom";
const CouresBookDetail = () => {
  const [courses, setCourses] = useState([])
  const Lange = localStorage.getItem('lang')

  useEffect(() => {
    const random = () => getCourses().then(function(result) {
      console.log(result);
      setCourses(result.data)
    })

    random()
  }, [])
  
  const navigate = useNavigate()

  if (typeof courses === 'undefined' || typeof courses === 'null') {
    return(
      <h1>Loading...</h1>
    )
  }
  return (
    <section
      className="experts_team_area sec_pad"
      style={{ marginTop: "50px" }}
    >
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title={lang.S_COURSE}
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          {courses && courses.map((course, key) => (
            <div className="col-lg-4 col-sm-6" key={key} onClick={() => {navigate(`/course/${course._id}`)}}>
              <Teamitem
                // link="/EmotionalAwareness"
                teamImage={`https://api.resto.oryxintl.co:3000/${course.image}`}
                memberN={Lange === "ENGLISH" ? course.name : course.arname}
                memberd={course.price+ lang.P_Price}
              />
            </div>
          ))}
          
          {/* <div className="col-lg-4 col-sm-6">
                        <Teamitem
                          link="/"
                         teamImage="team_8.jpg" memberN="Bailey Wonger" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Max Conversion" memberd="Social Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_10.jpg" memberN="Jake Weary" memberd="UI/UX designer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Justin Case" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_12.jpg" memberN="Norman Gordon" memberd="Web developer"/>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default CouresBookDetail;
