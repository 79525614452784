import React, { useState } from 'react'
import CustomNavbar from '../CustomNavbar'
import FooterAmani from '../Footer/FooterAmani'
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const SessionDates = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date.toISOString().split('T')[0]); // Logging selected date in ISO format
  };

  return (
    <div div className="body_wrapper">
        <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
        
        <FooterAmani/>
    </div>
  )
}

export default SessionDates