import React, {Component} from 'react';
import FooterData from '../Footer/FooterData';
import { Link, NavLink } from 'react-router-dom';

class Teamitem extends Component {
    render(){
        var {teamImage, memberN, memberd ,link}= this.props;
        return(
            <div className="ex_team_item">
      
                <img src={teamImage} width="80%" alt="team"/>
                <div className="team_content">
                    <a href=".#">
                        <h3 className="f_p f_size_16 f_600 t_color3">{memberN}</h3>
                    </a>
                    {/* <h5>web designer</h5> */}
                </div>
                <div className="">
                <a href={link}>
                    <div className="">
                        <ul className="list-unstyled">
                            {
                                FooterData.socialIcon.map(item=>{
                                    return(
                                        <li key={item.id}></li>
                                    )
                                })
                            }
                        </ul>
                        <div className="br"></div>
                        <a href=".#">
                            <h3 className="f_p f_size_16 f_600 w_color">{memberN}</h3>
                        </a>
                        <h5>{memberd}</h5>
                    </div>
                    </a>
                </div>
            </div>
        )
    }
}
export default Teamitem;