import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";

import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import MeditationdataBookDetail from "../components/Team/MeditationdataBookDetail";
import FooterAmani from "../components/Footer/FooterAmani";
const MeditationdataBook = () => {
  return (
    <div className="body_wrapper">
        <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      {/* <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="Img-05.jpg"
        Ptitle="Our MeditationdataBook"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      /> */}
      <MeditationdataBookDetail/>
      {/* <FooterTwo FooterData={FooterData} /> */}
      <FooterAmani/>
    </div>
  );
};
export default MeditationdataBook;
