import React, { useEffect, useState } from "react";
import Sectitle from "../Title/Sectitle";
import BuyNowCouresIteam from "../Team/BuyNowCouresIteam";
import { useNavigate, useParams } from "react-router-dom";
import lang from "../../lang/lang";
import { getSingleArticles, getSingleCourses, getSingleEBooks, getSinglePodcasts, getSingleSession, postSubscription } from "../../services/api";
import Axios from "axios";
import FooterAmani from "../Footer/FooterAmani";
import CustomNavbar from "../CustomNavbar";
const BuyPodcast = () => {
  const [courses, setCourses] = useState([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1);
  const [paymentStatus, setPaymentStatus] = useState('');
  const Lange = localStorage.getItem('lang')
  

  const id = localStorage.getItem('id')

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(parseInt(event.target.value));
  };

  const {paramssix} = useParams();

  

  useEffect(() => {
    const random = () => getSinglePodcasts(paramssix).then(function(result) {
      console.log(result);
      setCourses(result.data)
    })

    random()
  }, [])

  const navigate = useNavigate()

  const handlePayment = async () => {
    const paymentData = {
      InvoiceAmount: courses.price,
      CurrencyIso: "kwd",
    };
    try {
      const initiateResponse = await Axios.post(
        "https://api.resto.oryxintl.co:3000/initiatepayment",
        paymentData
      );

      // Assuming the response contains the necessary data to proceed with payment execution
      // (e.g., transaction ID or other payment-related data), extract it from the response
      const transactionId = initiateResponse.data.transactionId;

      // After successful payment initiation, you can proceed with payment execution
      const executePaymentData = {
        PaymentMethodId: 2,
        invoiceValue: courses.price,
        CallBackUrl: `https://amani.oryxintl.co/#/success/${courses.price}/${id}/${paramssix}`,
        ErrorUrl: 'https://amani.oryxintl.co/#/fail'
        // transactionId: transactionId,
        // Add any other necessary data for payment execution...
      };
      console.log('executePaymentData:', executePaymentData);
      // Execute the payment using the /executePayment API
      const executeResponse = await Axios.post(
        "https://api.resto.oryxintl.co:3000/executePayment",
        executePaymentData
      );
      window.open(executeResponse.data.Data.PaymentURL)
      console.log(executeResponse);

      if (courses.price === '0') {
        postSubscription(executePaymentData, paramssix)
      }

    } catch (error) {
      console.log(error);
    }
  
  };
  
  console.log('lang====>' +lang);


  if (typeof courses === 'undefined') {
    return(
      <h1>Loading...</h1>
    )
  }

  return (
    <div className="body_wrapper">
      <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      
    <section className="experts_team_area sec_pad"  style={{ marginTop: "50px" }}>
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title="Podcast"
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <BuyNowCouresIteam
              teamImage={`https://api.resto.oryxintl.co:3000/${courses.image}`}
              memberN={Lange === 'ENGLISH' ? courses.name : courses.arname}
              memberd={courses.price}
            />
          </div>

          <div className="col-lg-6 col-sm-6">
            {/* <BuyNowCouresIteam
              link="/"
              teamImage="team_8.jpg"
              memberN="Bailey Wonger"
              memberd="Marketer"
            /> */}
            <div className="Heading mt-2">
              <h5>{Lange === 'ENGLISH' ? courses.name : courses.arname}</h5>
            </div>
            <div className="PricesCoures mt-4 mb-3">
              <h5> {courses.price} KWD</h5>
            </div>
            {/* <div className="TotalSession mt-4 mb-3">
              <h4>Total Session : 1</h4>
            </div> */}
            <select type="text" class="form-control" name="payment" onChange={handlePaymentMethodChange}>
              <option value="1">KNET</option>
              <option value="2">VISA/MASTER</option>
            </select>
            <div className="BuyNow">
              <button className="signup_btn btn_hover saas_banner_btn mt_40" onClick={handlePayment}>
                Buy Now
              </button>
            </div>
            <div className="Description mt-4">
              <h5>{Lange === 'ENGLISH' ? courses.des : courses.ardes}</h5>
              <p className="mt-2 p-1">{courses.des}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterAmani />
    </div>
  );
};
export default BuyPodcast;
