// Fail.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Fail = () => {
  const navigate = useNavigate();
  const id = localStorage.getItem('id');

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Oops! Something Went Wrong</h1>
      <p style={styles.message}>We apologize for the inconvenience.</p>
      <button style={styles.button} onClick={() => {navigate('https://amani.oryxintl.co/')}}>
        Go back to Course
      </button>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '100px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#dc3545',
  },
  message: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default Fail;
