import React, { useEffect, useState } from 'react';
import CustomNavbar from '../../CustomNavbar';
import FooterAmani from '../../Footer/FooterAmani';
import { getAudio, getLink, getS, getVideo } from '../../../services/api';
import { useParams } from 'react-router-dom';

const Lang = localStorage.getItem('lang');

const SingleService = () => {
  const [datas, setDatas] = useState();
  const { paramsnine } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getS(paramsnine);
        setDatas(result.data);
        console.log(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [paramsnine]);

  const handleVideo = async (data) => {
    const res = await getVideo({id: data});
    if (res && res.data.path) {
      // Use window.open() to open the video link in a new tab
      window.open(`https://api.resto.oryxintl.co:3000/${res.data.path}`, '_blank');
    }
  }

  const handleAudio = async (data) => {
    const res = await getAudio({id: data});
    if (res && res.data.path) {
      window.open(`https://api.resto.oryxintl.co:3000/${res.data.path}`, '_blank');
    }
  }

  const handleLink = async (data) => {
    const res = await getLink({id: data});
    if (res && res.data.path) {
      window.open(res.data.path);
    }
  }

  if (typeof datas === 'undefined') {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="body_wrapper">
      <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      <div style={{ width: '80vw', marginLeft: '10vw', margin: '2vh', border: '1px solid black', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <img style={{width: '20vw', height: '20vh'}} src={`https://api.resto.oryxintl.co:3000/${datas.image}`} alt={Lang === 'ENGLISH' ? datas.name : datas.arname} />
        <div>
          <p>{Lang === 'ENGLISH' ? datas.name : datas.arname}</p>
          <p>{Lang === 'ENGLISH' ? datas.des : datas.ardes}</p>
          {datas.link && datas.link.map((data, key) => (
            <div onClick={() => handleLink(data)}>Link {key+1}</div>
          ))}
          {datas.video && datas.video.map((data, key) => (
            <div onClick={() => handleVideo(data)}>Video {key+1}</div>
          ))}
          {datas.audio && datas.audio.map((data, key) => (
            <div onClick={() => handleAudio()}>Audio {key+1} </div>
          ))}
        </div>
      </div>
      <FooterAmani />
    </div>
  );
};

export default SingleService;
