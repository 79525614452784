import React, { useEffect, useState } from 'react';
import FooterAmani from '../../Footer/FooterAmani';
import CustomNavbar from '../../CustomNavbar';
import Coures from '../Coures';
import { getSubsCont } from '../../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../Breadcrumb';
import SessionsFetch from './SessionsFetch';

const Services = () => {
  const [datas, setDatas] = useState([]);
  const { paramseight } = useParams();
  const id = localStorage.getItem('id');

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const result = await getSubsCont(id);
        setDatas(result.data);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    fetchSubscriptions();
  }, []);
  

  const navigate = useNavigate();

  if (!datas || datas.length === 0) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="body_wrapper">
      <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      <SessionsFetch />
      <div className="services-container">
        {datas.map((data, key) => (
          <div
            onClick={() => {
              navigate(`/service-detail/${data._id}`);
            }}
            className="service-item"
            key={key}
            style={{marginLeft: '2%'}}
          >
            <img src={`https://api.resto.oryxintl.co:3000/${data.image}`} style={{height: '10vh', width: '10vw'}} alt={data.name} />
            <div className="service-details">
              <p className="service-name">{data.name}</p>
              <p className="service-description">{data.des}</p>
            </div>
          </div>
        ))}
      </div>
      <FooterAmani />
    </div>
  );
};

export default Services;
