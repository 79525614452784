import React, { useEffect, useState } from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
import lang from "../../lang/lang";
import { getMeditation } from "../../services/api";
import { useNavigate } from "react-router-dom";
const MeditationdataBookDetail = () => {

  const [books, setBooks] = useState([])
  const Lange = localStorage.getItem('lang')
  const navigate = useNavigate()

  useEffect(() => {
    const random = () => getMeditation().then(function(response) {
      setBooks(response.data)
      console.log(response.data);
    })

    random()
  }, [])

  if (typeof books === 'undefined') {
    return(
      <h1>Loading...</h1>
    )
  } 
  return (
    <section className="experts_team_area sec_pad" style={{marginTop:"50px"}}>
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title={lang.S_MEDITATION}
          tClass="t_color3"
          TitleP=""
        />
        <div className="row">
          {books && books.map((book, key) => (
            <div className="col-lg-4 col-sm-6" onClick={() => {navigate(`/meditation/${book._id}`)}}>
              <Teamitem
                teamImage={`https://api.resto.oryxintl.co:3000/${book.image}`}
                memberN={Lange === "ENGLISH" ? book.name : book.arname}
                memberd={book.price+ lang.P_Price}
              />
            </div>
          ))}
          
          {/* <div className="col-lg-4 col-sm-6">
                        <Teamitem teamImage="team_8.jpg" memberN="Bailey Wonger" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Max Conversion" memberd="Social Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_10.jpg" memberN="Jake Weary" memberd="UI/UX designer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_9.jpg" memberN="Justin Case" memberd="Marketer"/>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="team_12.jpg" memberN="Norman Gordon" memberd="Web developer"/>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default MeditationdataBookDetail;
