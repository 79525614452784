import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import PodastdataBookDetail from "../components/Team/PodastdataBookDetail";
import FooterAmani from "../components/Footer/FooterAmani";
const PodastdataBook = () => {
  return (
    <div className="body_wrapper">
        <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      {/* <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="Img-05.jpg"
        Ptitle="Our PodastdataBook"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      /> */}
      <PodastdataBookDetail/>
      {/* <FooterTwo FooterData={FooterData} /> */}
      <FooterAmani />
    </div>
  );
};
export default PodastdataBook;
