import React from "react";
import FooterData from "./FooterData";
import { Link, NavLink } from "react-router-dom";
import lang from "../../lang/lang";

const FooterAmani = () => {
  return (
    <>
      <div className="" style={{background:"#b31336"}}>
        <div className="container">
          <div className="row" style={{ padding: "45px" }}>
            <div className="col-lg-6 col-sm-6">
              <NavLink to="/" className="f-logo">
                <img
                  src={require("../../img/AmaniFooter.png")}
                  style={{ width: "50%" }}
                  alt=""
                />
              </NavLink>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="f_social_icon" style={{ textAlign: "center" }}>
                <h3  style={{color:"white"}}>{lang.F_HEADING}</h3>
                {FooterData.socialIcon.map((item) => {
                  return (

                    <a target="_blank" href={item.url} key={item.id}>
                              <i className={item.icon}></i>
                            </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="Copyright mt-4" style={{ textAlign: "center" }}>
            <p style={{color:"white"}}>© Copyright © 2022 Amani Al Otaibi. All Right Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterAmani;
