import React, { Component, useLayoutEffect } from "react";
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
/*------ Pages-----*/
import { Home } from "./Pages/Home";
import DigitalMarketing from "./Pages/Digital-marketing";
import PaymentProcessing from "./Pages/Payment-processing";
import HRManagement from "./Pages/HR-Management";
import Startup from "./Pages/Startup";
import HomeCRM from "./Pages/Home-CRM";
import About from "./Pages/About";
import Service from "./Pages/Service";
import Process from "./Pages/Process";
import Team from "./Pages/Team";
import Portfolio2col from "./Pages/Portfolio-2col";
import Portfolio3col from "./Pages/Portfolio-3col";
import Portfoliofull4col from "./Pages/Portfolio-fullwidth-4col";
import PortfolioSingle from "./Pages/PortfolioSingle";
import Bloglist from "./Pages/Bloglist";
import BlogSingle from "./Pages/BlogSingle";
import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Landing from "./Pages/Landing";
import homesupport from "./Pages/home-support";
import Homeerp from "./Pages/Home-ERP";
import homeHosting from "./Pages/HomeHosting";
import homeSecurity from "./Pages/HomeSecurity";
import homeSoftwareDark from "./Pages/Home-software-dark";
import HomeAppShowcase from "./Pages/Home-app-showcase";
import HomeCloud from "./Pages/Home-cloud";
import HomeTracking from "./Pages/Home-tracking";
import HomeEvent from "./Pages/Home-event";
import HomeChat from "./Pages/Home-chat";
import Price from "./Pages/Price";
import Faq from "./Pages/Faq";
import ServiceDetails from "./Pages/ServiceDetails";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import BlogGridPage from "./Pages/BlogGridPage";
import NotFound from "./Pages/404";

// servies-pages
import CouresBook from "./Pages/CouresBook";
import MeditationdataBook from "./Pages/MeditationdataBook";
import SessiondataBook from "./Pages/SessiondataBook";
import EbookdataBook from "./Pages/EbookdataBook";
import ArticlesdataBook from "./Pages/ArticlesdataBook";
import PodastdataBook from "./Pages/PodastdataBook";


// BuyCoures-pages
import EmotionalAwareness from "./Pages/EmotionalAwareness";
import BuyCourse from "./components/Team/BuyNowCoures";
import BuyEbook from "./components/Team/BuyNowEbookItem";
import BuyMeditation from "./components/Team/BuyMeditation";
import BuySession from "./components/Team/BuySession";
import BuyPodcast from "./components/Team/BuyPodcast";
import BuyArticles from "./components/Team/BuyArticles";
import Subscribe from "./components/Team/Subscribe";
import Services from "./components/Service/Sservice/Services";
import SingleService from "./components/Service/Sservice/SingleService";
import SessionDates from "./components/Team/SessionDates";
import Success from "./components/Service/Sservice/Success";
import Fail from "./components/Service/Sservice/Fail";

const token = localStorage.getItem('token')

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }



  
  render() {



    return (
      <React.Fragment>
      <Router>
        <Wrapper>
          <Routes>
            <Route exact path={"/"} element={<Homeerp />} />
            {/* <Route
              path="/Digital-marketing"
              element={<DigitalMarketing}
            />
            <Route
              path="/Payment-processing"
              element={<PaymentProcessing}
            />
            <Route path="/HR-Management" element={<HRManagement} />
            <Route path="/Startup" element={<Startup} />
            <Route path="/Home-CRM" element={<HomeCRM} /> */}
            <Route path="/About" element={<About />} />
            {/* <Route path="/Service" element={<Service} /> */}
            {/* <Route path="/Process" element={<Process} /> */}
            {/* <Route path="/Team" element={<Team} /> */}


            {/* Servies-pages */}
            <Route path="/CouresBook" element={<CouresBook />} />
            <Route path="/course/:paramsone" element={<BuyCourse />} />
            <Route path="/MeditationdataBook" element={<MeditationdataBook />} />
            <Route path="/meditation/:paramsthree" element={<BuyMeditation />} />
            <Route path="/SessiondataBook" element={<SessiondataBook />}/>
            <Route path="/session/:paramsfour" element={<BuySession />}/>
            <Route path="/EbookdataBook" element={<EbookdataBook />}/>
            <Route path="/ebook/:paramstwo" element={<BuyEbook />}/>
            <Route path="/ArticlesdataBook" element={<ArticlesdataBook />}/>
            <Route path="/articles/:paramsfive" element={<BuyArticles />}/>
            <Route path="/PodastdataBook" element={<PodastdataBook />}/>
            <Route path="/podcast/:paramssix" element={<BuyPodcast />}/>

            {/* BuyCoures */}
            <Route path="/EmotionalAwareness" element={<EmotionalAwareness />}/>
            <Route path="/SignIn" element={<SignIn />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/subscribe/:paramsseven" element={<Subscribe />} />
            <Route path="/services/:paramseight" element={<Services />} />
            <Route path="/service-detail/:paramsnine" element={<SingleService />} />
            <Route path="/addsession-dates/paramsten" element={<SessionDates /> } />
            <Route path="/success/:paramstwelve/:paramsthirteen/:paramsfourteen" element={<Success />} />
            <Route path="/fail" element={<Fail/>} />
            {/* <Route path="/Portfolio-2col" element={<Portfolio2col} />
            <Route path="/Portfolio-3col" element={<Portfolio3col} />
            <Route
              path="/Portfolio-fullwidth-4col"
              element={<Portfoliofull4col}
            />
            <Route
              path="/PortfolioSingle"
              element={<PortfolioSingle}
            /> */}
            {/* <Route path="/Bloglist" element={<Bloglist} />
            <Route path="/BlogSingle" element={<BlogSingle} />
            <Route path="/BlogGridPage" element={<BlogGridPage} /> */}
            <Route path="/Contact" element={<Contact />} />
            {/* <Route path="/Landing" element={<Landing} />
            <Route path="/home-support" element={<homesupport} />
            <Route path="/HomeHosting" element={<homeHosting} />
            <Route path="/Home-ERP" element={<homeERP} />
            <Route path="/HomeSecurity" element={<homeSecurity} />
            <Route
              path="/HomeAppShowcase"
              element={<HomeAppShowcase}
            />
            <Route
              path="/Home-software-dark"
              element={<homeSoftwareDark}
            /> */}
            {/* <Route path="/Home-cloud" element={<HomeCloud} />
            <Route path="/Home-Tracking" element={<HomeTracking} />
            <Route path="/Home-event" element={<HomeEvent} />
            <Route path="/Home-chat" element={<HomeChat} />
            <Route path="/Price" element={<Price} />
            <Route path="/Faq" element={<Faq} />
            <Route path="/ServiceDetails" element={<ServiceDetails} />
            
            */}
            <Route element={<NotFound />} />
          </Routes>
        </Wrapper>
      </Router>
      </React.Fragment>
    );
  }
}

export default App;
