import React, { useEffect } from "react";
import CustomNavbar from "../components/CustomNavbar";
import HomeERP from "../components/Banner/ErpBanner";
import Coures from "../components/Service/Coures";
import ErpAction from "../components/ErpAction";
import ErpFeatures from "../components/Features/ErpFeatures";
import Erpanalytics from "../components/Erpanalytics";
import ErpCustomerlogo from "../components/ErpCustomerlogo";
import ErpActionTwo from "../components/ErpActionTwo";
import ErpTestimonial from "../components/Testimonial/ErpTestimonial";
import FooterErp from "../components/Footer/FooterErp";
import FooterData from "../components/Footer/FooterData";
import MarketingTestimonial from "../components/Testimonial/MarketingTestimonial";
import BannerData from "../components/Banner/BannerData";
import FooterAmani from "../components/Footer/FooterAmani";
import lang from "../lang/lang";


const Homeerp = () => {

  // useEffect(()=>{
  //   console.log(localStorage.getItem('lang'),'LOCAL STORAGE')
  // },[localStorage.getItem('lang')])

  // console.log(lang)
  return (
    <div className="body_wrapper">
      <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
      {/* <HomeERP/>  */}
      <Coures />
      <MarketingTestimonial BannerData={BannerData} />
      <ErpAction />
      <ErpTestimonial />
      {/* <ErpFeatures/>
            <Erpanalytics/>
            <ErpCustomerlogo/> */}

      {/* <ErpActionTwo/> */}
      {/* <FooterErp fClass="h_footer_dark_two" FooterData={FooterData}/> */}
      <FooterAmani />
    </div>
  );
};
export default Homeerp;
