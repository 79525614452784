import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Contacts from '../components/Contacts';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import FooterAmani from '../components/Footer/FooterAmani';

const About = () => {
    return(
        <div className="body_wrapper">
             <CustomNavbar mClass="erp_menu" hbtnClass="er_btn" nClass="mr-auto" />
            {/* <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Contac Us" Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"/> */}
            <Contacts/>
            {/* <FooterTwo FooterData={FooterData}/> */}
            <FooterAmani/>
        </div>
    )
}
export default About;