const BannerData = {
  AgencyText: [
    {
      id: 1,
      btitle: "Starategic design and technology agency",
      description:
        "Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.",
    },
  ],
  ClientsLogo: [
    {
      id: 1,
      image: "logo_01.png",
    },
    {
      id: 2,
      image: "logo_02.png",
    },
    {
      id: 3,
      image: "logo_03.png",
    },
    {
      id: 4,
      image: "logo_04.png",
    },
    {
      id: 5,
      image: "logo_05.png",
    },
  ],
  AgencyTestimonial: [
    {
      id: 1,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "الشي اللي قاعدة تسوينه من بعد الله وااايد جبار ويشكر الحمدلله مفروض السبت اسويه بس دقوا قالو لازم تسوينه اليوم  الحمد لله الحمد لله الله يحميكم",
    },
    {
      id: 2,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "واللله ما توقعت بهالسرعة ادري ان بيصير إلي انا ابيه و الموضوع مع بيتحرك بس ماهقيت جذي ان بعد الجلسة على طول بس لان رفعت طاقتي ممتنة لج جدا جدا هذا بيزيد عندي الحماس ما يلوق عليج اقل من جذي اصلا ماراح ارضى بأقل",
    },
    {
      id: 3,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description: [
        "امااااني ",
        "سلیبیمه سلیبییمه ",
        "وتخيلي يقولون احتمال كبير ان ماکو التهاب اصلا وغلطان مو شايف الاشعه عدل",
      ],
      // description: 'امااااني سلیبیمه سلیبییمه وتخيلي يقولون احتمال كبير ان ماکو التهاب اصلا وغلطان مو شايف الاشعه عدلx',
    },
    {
      id: 4,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "تذكرين الاشياء اللي كنت ابيهم بالعلاقه  اللي سويناهم بالكورس اليديد  تخيلي دش علي واحد  قالي انا صارلي سنه قاعده احاول اكلمج انتي مو معطتني وجه  وحيل بالاهتمام كل يوم يدش يكلمني ومهتم",
    },
    {
      id: 5,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "يعطيج العافيه ي اقوى كوتش بالعالم کله .. خوش كورس وقوي جدا .. انا خذيت معاج اكثر من كورس وكل كورس يتميز بشي وايد يطور بالذات بس هذا غير العمق اللي فيه والنتيجه اللي شفتها بوقت قصير عرفت شكثر اهوا مختلف . وناطريبيين كورسات ثانیه Keep Going بعد ما شبعنا",
    },
    {
      id: 6,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "انا صرت وحده ثانيه فعلًا امس لميت خواتي اول مره بحياتي قبل ماكنا نسوي جذي نستحي كان في حاجز كبير بيننا واخوي كلمته بثقه كبيره بعد ماكنت احس بانعدام ثقه بنفسي معاه انا فعلا صرت شخص ثاني",
    },
    {
      id: 7,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "العلاقات أكثر موضوع يؤثر فينا وعلينا، يؤثر على مهنتنا وصحتنا ويؤثر على معرفتنا بأنفسنا. رووووعه انا الحين قاعده اطبق الماده بالسفر عميبييق الكلام والتمارين فعلاً أثرت فيني مشكووووره یا اماني على هالجهد  وبس بالنسبه للاسئله عن الدوره نقدر نسأل هنيه عن كورس شاكرا العلاقات",
    },
    {
      id: 8,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "جبا||||ر عن تجررررربه صار ينضرب فيني المثل من عقب ما كنت الطياره يجروني جر عشان اركب الحين الحمدلله قبلهم اركض . ممر بعد جلسات شفاء المخاوف",
    },
    {
      id: 9,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "بعد جلستين من العلاج الطاقي، صرت أحس إني متقبلة نفسي أكثر و قل الشعور بالتأنيب، و صرت لما أسترجع الصدمة النفسية ما أحس بأي شعور سلبي، بس أحتاج أشتغل على تصحيح المعتقدات بعد.. ترقبي زيارة قريبة مقترحات لهذه المرحلة واظن التنظيف مجدي نوعا ما اكتشف ان هالمرة جتني الدورة الشهرية بدون آلام !! وجلسات التنظيف الطاقي وحدة بوحدة",
    },
    {
      id: 10,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "مساء النور والسرور الغالية بصراحه فيه فرق ملموس بعد الجلسات .. بالنسبة للكورس الاول ألاحظ أنه عندي وعي بمشاعري .. يعني اذا حسيت بشيء اسأل نفسي عن سبب هالاحساس .. وأراجع يومي او الرابط اللي خلاني احس بهالمشاعر .. احس عندي هدوء وسلام واستيعاب لأمور كثيرة كانت توترني . عندي تحمل مسؤليه لمواقفي اكثر .. والحمدلله ماعدت احس بصراحه بالضيق مثل قبل اقدر اقول بنسبة 85‎%‎افضل الفضل لله ثم لج الغالية وجلسة تجلي الهدف عجيبة والله باكيج حب الذات العجيب",
    },
    {
      id: 11,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "هلو حبي .. تصدقين هالتمرين وايد قوي مادري ليش كنت ظالمته هذا اللي صج عرفت منه اني فعلا تغيرت الحمدلله و لما سويته قارنت اجوبتي ذاك الوقت قبل سنتين لما سويته والحين الاجوبه فيها تغييير 100 100 100 وبنطر اشوف نتایجه علي هالاسبوع واقولج تطبيقات كورس شاكرا العلاقات رح تغیر اسلوب حياتك وتصنع الفرق في كل مرة تعملها",
    },
    {
      id: 12,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "شنو هالهدوء و السكينه و الاسترخاء اللي انا فيهم !!!! ٤٧ سنه ما حسيت بهالراحه عرفنا ال key حق كل شي و اشتغلنا عليه صح مشكوووووووره احبج بعد جلسة تنظيف طاقة وكوتشينق",
    },
    {
      id: 13,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "اماني انتي عجيبه  انتي صج صج عجيبه انتي مو بس عجيبه  انتي اقوى كوتش بالكره الارضيه نتاااایج جلسة اليوم جبا||||اره  بعد جلسة كوتشينج",
    },
    {
      id: 14,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description: [
        'جلسات حب الذات كانت من أجمل الجلسات الي خذيتها معاج . من اول جلسة حسيت بفرق حالة من الهدوء والاتزان.. كان في حالة وضوح من فترة طويلة مامرت علي ساعدتيني حييل باني افهم واستوعب بعض الامور الي كانت تصير لي الجلسة الثانية كانت وقعها عجيب علي يمكن لأول مره اشعر بحب الجسد" body "love كان عميق وشعرت براحة كبيرة مع جسمي لما فهمت الي مر فيه وتأمل الحضور بالجسد ساعد وايد على هالشي من بعد الجلستين احس بنشاط كبير شعور بالخفة والحب وجدا حسيت بتطور بعلاقتي بشريكي شكرا من القلب حبيبتي. You are the best coach ever.',
        "باکج حب الذات يعيد تنظيم وترتيب شؤونك الداخلية",
      ],
    },
    {
      id: 15,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "بعد تأمل شفاء المخاوف امس و يا عمري والله انتي الجمال اسم الله عليبيج والله اشكرج على التامل حيل حيل تخيلي قاعده اكتب الكلام اخذ نوتس وباذن الله اطبق وألقى اسهل وأكبر منفعه كنت قاعده  أنا بعد التأمل",
    },
    {
      id: 16,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "هلا اماني شلونج حبيبتي حبيت اشكرج على جهدج و اعدادج لبکچ جلسات خاص لمعالجة مشكلتي اللي ازمت حياتي و نقصت علي كل نجاح و فرح انا اعيشه مشکوره على فهمج للمشكله و تفهمج للأفكار اللي تزعجبني خليتيني احط ايدي على اساس المشكله و خليتيني افهمها و عرفت الاسباب اللي ادت لها و ساعدتيني على زيادة الوعي و الادراك عندي بحيث اني بديت اكون واعيه للافكار و البرمجات القديمه اللي تضايقني انا قاعده احس اني احسن و حياتي قاعده تتحسن كل يوم احسن من اللي قبله انتي مشكوره واااايد و انا بعد مشكوره اني قاعده ابذل جهد معاج عشان اغير من نفسي للأحسن احبج وايد  بعد جلسات حب الذات",
    },
    {
      id: 17,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "اماني و و تذكرين القائمه اللي كتبت اسامي الاشخاص اللي ما ابيهم بحياتي توو جاني خبر ان هالبنت اللي سجلت اني ما ابيها بحياتي نقلت من عندنا راحت اداره ثانيه .... مع انها سنين ب هالمكتب مافكرت تنقل .!! اوف شنو الحياه سهله وبسيطه بس مانعرف نركز ونجلب اللي نبيه ونبي نشوفه بسس - واااو الحمدلله الحمدلله و جدا سهلة وبسيطة بس نوضح اللي نبيه - بعد جلسة كوتشينج",
    },
    {
      id: 18,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "سلام استاذه أماني شكرا على اهتمامج فيني بالجلسات كنت وايد محتاجيها ضروف الي مريت فيها سوت معاي صدمه قويه و دخلت نوبة أكتئاب شديده غيرتني من إنسانه طموحه و نشطه الي إنسانه فاقده لذة الحياة بس الحمد لله بفضل الله و صديقتي نورتني حق جلساتچ رجعت جزء كبييير من روحي الي ضاعت مني بعد أول جلسه كنت بس هاديه و في استقرار نفسي فقدته من شهور و الجلسة ثانيه ردت جزء من شخصيتي الي راحت بس فيها تطور حطيت لي مبادء جديد و أفكار متطورة علشان اتماشي مع المجتمع الجديد الجلسة الثالثة بديت اطبق القرارات الي أتخذتها من الجلسة الثانيه بقلب قوي و حبيت نفسي أكثر و عرفت قيمة نفسي و جسدي و روحي أماني ريحتيني وايد الله يسعدج في الدارين شكرا لج من كل قلبي رديتيني حق عيالي و اللهم أزل الغمه عن هذه الأمه أتمنى ألتقى فيچ بعد زوال هل الغمه و أشكرك من كل قلبي باكيج حب الذات",
    },
    {
      id: 19,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description: [
        "بعد تأمل شفاء العلاقات اماني  امس اول مرة يسوي معاي شفاء العلاقات .. قام اليوم يقول يحس بشعور غريب حلو منعش.. قالي شكريها وايد ويعطيها العافية ",
        "تقدرون تطلبونه ويوصلكم مباشر على بريدكم الالكتروني",
      ],
    },
    {
      id: 20,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "-الجلسة الأولى ساعدتني انظم مواقع الناس في حياتي وبالتالي ترتبت اولوياتي طبعا التأمل الرجوع لقلبي كان مفيد جدا ساعدني على التركيز اكثر وشال الشتات من مخي والأسئلة اللي مع التأمل ساعدتني اشوف مستقبل باذن الله احاول ارسمه لنفسي - الجلسة التانية كان تأمل الرجوع للقلب اسهل كتير وساعدني تأمل الطفل الداخلي ان صورتي عن ذاتي تتغير للاحسن ولو ان كان في مقاومة ولوم وعتاب كثير . -الجلسة الثالثة ألطف جلسة لان صاحبها توكيدات إيجابية عن نفسي وعن محيطي. بس الصراحة الجلسات مرعبه اماني . يعني نعالج ايه ولا ايه بالظبط  تحتاج استمرارية واكيد لها تكملة ممتنة لكي من كل قلبي على تحملك ليا ووقوفك جنبي  تخطيت أزمات كثيرة واخدت قرارات بفضل الله ثم مساندتك ليا بعد بعد جلسات باكيج حب الذات",
    },
    {
      id: 21,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "لي تجارب سابقة مع كوتش/ أماني العتيبي وتنظيفاتها وجلساتها العجيبة اللي كانت تفعل مفعول السحر اللذيذ في علاقي الزوجية والحين أنا بمرحلة لخبطة بحياتي وحاسة إني أعرف شنو أسوي بس مو قادرة أسويه! الله ذكرني بالجلسات والحمدالله إني ابتديت معاها! في البداية ما كنت قادرة أسيطر على مشاعري، حزن وندم وبكاء شديد اللي صج مادري من وين أبدأ! ابتدينا ب ثلاث جلسات أولية، ساعدتني فيها شوي أفهم وضعي، وأيضا على مستوى التطبيق قمت باتخاذ خطوة ما كنت حتى أحلم إني ممكن أسويها! ولكنها خطوة كانت حاسمة ساعدتني أعرف أنا وين، وين بروح وأبدأ المشوار بهذا الاتجاه، كل يوم بيومه! وحاليا مستمرة باذن الله بالجلسات مرة بالأسبوع عشان فعلا أنجح بتوجيه أفكاري ومشاعري بالاتجاه اللي يناسب رغباتي، مع العلم اللي يكون خلال الجلسة ليس فقط سرد لنصائح، بل تطبيق عملي خلال الجلسة على كل ما يمكن أن يساعدني لفهم نفسي بشكل أفضل. بعد بعد جلسات باكيج حب الذات وجلسات تنظيف الطاقة",
    },
    {
      id: 22,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "اماني و|||||||||يد شكر|||| اليوم انا احس نفسي احسن بواااايد وفرش وطاقتي احسن - الحمد لله الحمد لله  احس اني خفيفه وغير وحتي بالشغل احس فيه تغيير  اللهم بارك الحمدلله هذي اللي تستحقينه انج تكونين مرتاحة وسعيدة وبعد بعد بنكمل ونتغير اكثر بعد عمري مشكوووره وايد  اثناء فترات جلسات باكيج حب الذات",
    },
    {
      id: 23,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description: [
        "بعد قعدنا اليوم شكر|||| اماني من كل قلبي ",
        " ابدعتتتي  واید فرق علي وضعي بعد ماطلعت منج شکرا|||بعد جلسة تدريب وتنظيف طاقي",
      ],
    },
    {
      id: 24,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "انتي اللي مشكوووره اماني مشكورة انج جزء من رحلتي خذيتي بيدي صج احبج یا اجمل وارق انسانة بعد جلسة شفاء الطفل الداخلي",
    },
    {
      id: 25,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "يعطيج العافيه ي اقوى كوتش بالعالم کله .. خوش كورس وقوي جدا خذيت معاج اكثر من كورس وكل كورس يتميز بشي وايد يطور بالذات بس هذا غير غير العمق اللي فيه والنتيجه اللي شفتها بوقت قصير عرفت شكثر اهوا مختلف . وناطريييين كورسات ثانيه Keep Going بعد ما شبعنا TOP جلسات حب الذات من أهم الجلسات اللي ترتب حياتنا",
    },
    {
      id: 26,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "هلا استاذه اماني شحالج .. استاذه بقالي يومين من برنامج شاكرا العلاقات الحمدلله رب العالمين اسمع بين الانا و الوعي واطبق جلسات الشفاء مع برنامج شاكرا العلاقات اللي اشتركت فيه كنت اريد استغل الاجازه والوقت بس اللي صار اني تعبت شوي وصار اتييني مشاعر لها سنین فجاه و صور ومواقف ودموع واقعد طول اليوم دموعي تنزل .. فطبقت كل شي قلتيه واستمريت لين الثلاثاء الصبح امس وعیت اتنفس . الالام اللي كنت احسهن في ريولي وظهري لليوم الثاني ما احسبهن .. احس اني اتنفس . اسفه عالاطاله بس انا صدق اتنفس  تطبيقات كورس شاكرا العلاقات من أقوى التطبيقات اللي تعمل نقلة حقيقية في الوعي",
    },
    {
      id: 27,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "مساء الخير استاذه صارت مثل المعجزه انتقلت وبيبدا دوامي باذن الله من يوم الاثنين بس المعجزه وين الموارد البشريه ما طاع بسبب امور اداریه وردوا عليه الاسبوع اللي طاف أنه خلاص ماشي شاغر تفاهموا مدراء الاقسام مع بعض ونقلوني بشكل غير رسمي لين امور الاتش ار تنحل ما اقولج كيف في هالمده معاج تعلمت مب بس تحقيق الهدف .. تعلمت من اول ويديد الايمان وتهذيب التفكير والمشاعر  نية هالسنه كانت تحقيق الايمان والله اهداني اماني تراويني ياه بعد جلسة تجلي الهدف",
    },
    {
      id: 28,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "مساء السعادة  الي عن طريقها ممتنة بصراحة لج تعرفت عليج أحس أني وايد وايد احسن من شعور اللخبطة الي كنت احسبه والبحث عن ذاتي مشكورة مشكورة مشكورة بعد جلسات باكيج حب الذات",
    },
    {
      id: 29,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "مساء السعادة  الي عن طريقها ممتنة بصراحة لج تعرفت عليج أحس أني وايد وايد احسن من شعور اللخبطة الي كنت احسبه والبحث عن ذاتي مشكورة مشكورة مشكورة بعد جلسات باكيج حب الذات",
    },
    {
      id: 30,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "آراء العملاء بعد الجلسات  اماني! انا ما سويت شي بعد اتصالنا .. بس طلعت قعدت شوي عند هلي ورجعت فرفتي بدلت ونمت قمت الصبح على مسج من ممثل انه يبي يكلمني (اتصالي معاه اليوم العصر لانه بامريكا) .. وتوه من شوي.. جاني اوفر الشغل الجديد",
    },
    {
      id: 31,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "آراء العملاء بعد الجلسات اماني انت غيرت كتير شغلات فيني فعلا وبحب احضر الايفات تبعك بحس فيها بالسلام وبحس بانوثتي وتقديري لذاتي شكرا الك ولوجودك",
    },

    {
      id: 32,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "آراء العملاء بعد الجلسات اماااني بس انا داشه عليج اقولج اني احبج و مشکوره على كل شي عسى سويتيه معاي الله يزيدج من فضله",
    },
    {
      id: 33,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "انتي اللي مشكوووره اماني مشكورة انج جزء من رحلتي خذيتي بيدي صج احبج یا اجمل وارق انسانة بعد جلسة شفاء الطفل الداخلي",
    },
    {
      id: 34,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "يعطيج العافيه ي اقوى كوتش بالعالم کله .. خوش كورس وقوي جدا خذيت معاج اكثر من كورس وكل كورس يتميز بشي وايد يطور بالذات بس هذا غير غير العمق اللي فيه والنتيجه اللي شفتها بوقت قصير عرفت شكثر اهوا مختلف . وناطريييين كورسات ثانيه Keep Going بعد ما شبعنا TOP 1 جلسات حب الذات من أهم الجلسات اللي ترتب حياتنا",
    },
    {
      id: 35,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "هلا استاذه اماني شحالج .. استاذه بقالي يومين من برنامج شاكرا العلاقات الحمدلله رب العالمين اسمع بين الانا و الوعي واطبق جلسات الشفاء مع برنامج شاكرا العلاقات اللي اشتركت  فيه كنت اريد استغل الاجازه والوقت بس اللي صار اني تعبت شوي وصار اتييني مشاعر لها سنین فجاه و صور ومواقف ودموع واقعد طول اليوم دموعي تنزل .. فطبقت كل شي قلتيه واستمريت لين الثلاثاء الصبح امس وعیت اتنفس . الالام اللي كنت احسهن في ريولي وظهري لليوم الثاني ما احسبهن .. احس اني اتنفس .  اسفه عالاطاله بس انا صدق اتنفس تطبيقات كورس شاكرا العلاقات من أقوى التطبيقات اللي تعمل نقلة حقيقية في الوعي",
    },
    {
      id: 36,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description: [
        "مساء الخير ",
        "استاذه صارت مثل المعجزه انتقلت وبيبدا دوامي باذن الله من يوم الاثنين  بس المعجزه وين الموارد البشريه ما طاع بسبب امور اداریه وردوا عليه الاسبوع اللي طاف أنه خلاص ماشي شاغر  تفاهموا مدراء الاقسام مع بعض ونقلوني بشكل غير رسمي لين امور الاتش ار تنحل ما اقولج كيف في هالمده معاج تعلمت مب بس تحقيق الهدف .. تعلمت من اول ويديد الايمان وتهذيب التفكير والمشاعر نية هالسنه كانت تحقيق الايمان والله اهداني اماني تراويني ياه  بعد جلسة تجلي الهدف",
      ],
    },
    {
      id: 37,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "مساء السعادة الي عن طريقها ممتنة بصراحة لج تعرفت عليج أحس أني وايد وايد احسن من شعور اللخبطة الي كنت احسبه والبحث عن ذاتي مشكورة مشكورة مشكورة  بعد جلسات باكيج حب الذات",
    },
    {
      id: 38,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "آراء العملاء بعد الجلسات اماني!  انا ما سويت شي بعد اتصالنا .. بس طلعت قعدت شوي عند هلي ورجعت فرفتي بدلت ونمت  قمت الصبح على مسج من ممثل انه يبي يكلمني (اتصالي معاه اليوم العصر لانه بامريكا) .. وتوه من شوي.. جاني اوفر الشغل الجديد",
    },
    {
      id: 39,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "آراء العملاء بعد الجلسات اماني انت غيرت كتير شغلات فيني فعلا وبحب احضر الايفات تبعك بحس فيها بالسلام وبحس بانوثتي وتقديري لذاتي شكرا الك ولوجودك",
    },
    {
      id: 40,
      // image: 'author_img.png',
      // authorName: 'Lurch Schpellchek',
      // authorPost: 'UI/UX designer',
      description:
        "آراء العملاء بعد الجلسات اماااني بس انا داشه عليج اقولج اني احبج و مشکوره على كل شي عسى سويتيه معاي الله يزيدج من فضله",
    },
  ],
};
export default BannerData;
