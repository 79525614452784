import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postSubscription } from '../../../services/api';

const Success = () => {
  const { paramstwelve, paramsthirteen, paramsfourteen } = useParams();
  const navigate = useNavigate();
  const id = localStorage.getItem('id');

  const postSub = async () => {
    const data = {
      amount: paramstwelve,
      id: paramsthirteen,
      dates: localStorage.getItem('dates')
    };

    const result = await postSubscription(data, paramsfourteen);
    // You might want to handle the result here
  };

  useEffect(() => {
    postSub();
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Thank You!</h1>
      <button style={styles.button} onClick={() => {navigate('https://amani.oryxintl.co/')}}>
        Go to Course
      </button>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '100px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default Success;
