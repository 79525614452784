import React, { useEffect, useState } from 'react';
import { getDateOfSession } from '../../../services/api';

const SessionsFetch = () => {
  const [dates, setDates] = useState();

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const result = await getDateOfSession();
        setDates(result.data);
        console.log(result.data);
      } catch (error) {
        console.error('Error fetching dates:', error);
      }
    };

    fetchDates();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format the date to your desired format
  };

  const formatTime = (timeString) => {
    // Implement time formatting if needed
    return timeString;
  };

  return (
    <div>
      {dates &&
        dates.map((value, key) => (
          <div key={key} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>Session Dates:-
              {value.date &&
                value.date.map((dateValue, dateKey) => (
                  <div key={dateKey}>{formatDate(dateValue)}</div>
                ))}
            </div>
            <div>Time:-
              {value.time &&
                value.time.map((timeValue, timeKey) => (
                  <div key={timeKey}>{formatTime(timeValue)}</div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default SessionsFetch;
