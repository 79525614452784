import React, { useEffect, useState } from 'react'
import { getS } from '../../services/api'
import { useParams } from 'react-router-dom'
import Axios from 'axios'

const Subscribe = () => {
    const [data, setData] = useState()
    const { paramsseven } = useParams()
    useEffect(() => {
        const random = () => getS(paramsseven).then(function(result){
            setData(result.data)
            console.log(result.data);
        })
        random()
    }, [])

    
  
  
  return (
    <div>
      
    </div>
  )
}

export default Subscribe
